var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('div', {
    staticClass: "d-flex"
  }, [_c('b-link', {
    staticClass: "text-dark",
    attrs: {
      "to": {
        name: 'statistik'
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "ArrowLeftIcon"
    }
  })], 1), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.paketDetail.name) + " ")])], 1), _c('b-card', [_c('v-select', {
    attrs: {
      "id": "sekolah",
      "options": _vm.listJadwal,
      "label": "name",
      "placeholder": "Pilih Jadwal"
    },
    model: {
      value: _vm.jadwal,
      callback: function callback($$v) {
        _vm.jadwal = $$v;
      },
      expression: "jadwal"
    }
  })], 1), _c('div', {
    staticClass: "mt-2"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('chartjs-component-bar-chart', {
    attrs: {
      "height": 400,
      "data": _vm.chartjsDataBar.data,
      "options": _vm.chartjsDataBar.options
    }
  })], 1)], 1)], 1), _vm._m(0), _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('div', {}, [_c('b-row', _vm._l(_vm.dataPersentase, function (item, i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "lg": "4",
        "sm": "6"
      }
    }, [_c('b-card', [_c('h5', [_c('b', [_vm._v(" " + _vm._s(item.ujian_name))])]), _c('b-row', [_c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_c('ul', {
      staticStyle: {
        "margin-left": "20px",
        "padding": "0"
      }
    }, [_c('span', [_c('li', {
      staticStyle: {
        "color": "#1442dc"
      }
    }, [_vm._v(_vm._s(item.jumlah_benar) + "%")]), _c('li', {
      staticStyle: {
        "color": "#b9483d"
      }
    }, [_vm._v(_vm._s(item.jumlah_salah) + "%")]), _c('li', {
      staticStyle: {
        "color": "#d09792"
      }
    }, [_vm._v(_vm._s(item.jumlah_kosong) + "%")])])])]), _c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_c('chartjs-component-doughnut-chart', {
      attrs: {
        "height": 100,
        "data": _vm.dataDoughnut(item.jumlah_benar, item.jumlah_salah, item.jumlah_kosong),
        "options": _vm.chartjsDataDoughnut.options
      }
    })], 1)], 1)], 1)], 1);
  }), 1)], 1)])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('ul', {
    staticClass: "d-flex"
  }, [_c('li', {
    staticStyle: {
      "color": "#1442dc"
    }
  }, [_vm._v("Benar")]), _c('li', {
    staticClass: "ml-4",
    staticStyle: {
      "color": "#b9483d"
    }
  }, [_vm._v("Salah")]), _c('li', {
    staticClass: "ml-4",
    staticStyle: {
      "color": "#d09792"
    }
  }, [_vm._v("Kosong")])])]);
}]

export { render, staticRenderFns }